import React, { useEffect } from "react";
import "./SecondHeader.scss";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import { Select } from "antd";
import {
  fetchFacilities,
  setSelectedFacility,
} from "../../../store/facilities.reducer.tsx";
import { Facility } from "../../../interfaces/facility.interface.tsx";

export interface SecondHeaderProps {
  updateFacility: (val: string) => void;
  email: string;
  token: string;
}

export const SecondHeader = (props: SecondHeaderProps) => {
  const { email, token } = props;
  const dispatch = useAppDispatch();
  const facilities: Facility[] = useTypedSelector(
    (state) => state.facility.facilities
  ) as Facility[];
  const loading = useTypedSelector((state) => state.facility.loadingFacilities);
  const selectedFacility = useTypedSelector(
    (state) => state.facility.selectedFacility
  );

  useEffect(() => {
    dispatch(fetchFacilities({ email, token }));
  }, [dispatch, email, token]);

  const isActiveLink = (url: string): boolean => {
    return window.location.href.endsWith(url);
  };

  if (loading) {
    return null;
  }

  return (
    <div className="second-header">
      <Link to="/home" className="header-link">
        Home
      </Link>
      <nav>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={
                isActiveLink("/dashboard")
                  ? "header-link active-link"
                  : "header-link"
              }
            >
              Dashboards
            </NavLink>
          </li>
          {facilities && facilities.length > 0 && (
            <li>
              <Select
                showSearch
                defaultValue={selectedFacility}
                className="facility-select"
                onChange={(value) => dispatch(setSelectedFacility(value))}
                options={
                  facilities.map((facility: Facility) => ({
                    value: facility.site_id,
                    label: `${facility.site_id} - ${facility.site}`,
                  })) as any
                }
              />
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};
