import React from "react";
import { Modal } from "antd";

import "./AccountCreateConfirmation.scss";
import { useAppDispatch } from "../../../store/app.store.tsx";
import { setShowAccountCreateConfirmation } from "../../../store/modal.reducer.tsx";
import { useNavigate } from "react-router-dom";

export const AccountCreateConfirmation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOk = () => {
    dispatch(setShowAccountCreateConfirmation(false));
    navigate("/");
  };

  return (
    <Modal
      centered
      okText="Procceed to Login"
      closeIcon={false}
      open={true}
      title="Account Created"
      onOk={handleOk}
      footer={(_, { OkBtn }) => (
        <>
          <OkBtn />
        </>
      )}
    >
      <p>
        Your account has been registered successfully. Please procceed to the
        login.
      </p>
    </Modal>
  );
};
