import React, { useEffect, useMemo, useState } from "react";

import "./AddUser.scss";
import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import { fetchRoles } from "../../../store/authentication.reducer.tsx";
import {
  Flex,
  Spin,
  Form,
  Input,
  Card,
  Select,
  Button,
  Alert,
  Checkbox,
} from "antd";
import { Role } from "../../../interfaces/auth.interface.tsx";
import { CreateUser } from "../../../store/user.thunk.tsx";
import { useNavigate } from "react-router-dom";
import { clearSelectedUser } from "../../../store/user.reducer.tsx";

const { Option } = Select;

export const AddUser = (props: { token: string }) => {
  const dispath = useAppDispatch();
  const navigate = useNavigate();
  const { token } = props;
  const [form] = Form.useForm();
  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false);

  const loadingRole = useTypedSelector(
    (state) => state.authentication.loadingRoles
  );
  const roleError = useTypedSelector((state) => state.authentication.roleError);
  const roles = useTypedSelector((state) => state.authentication.appRoles);
  const creatingUser = useTypedSelector(
    (state) => state.user.loaders.creatingUser
  );
  const creatingUserError = useTypedSelector(
    (state) => state.user.errors.creatingUser
  );
  const selectedUser = useTypedSelector(
    (state) => state.user.selectedUser.data.userInfo
  );

  useMemo(() => {
    if (loadingRole !== "DONE") {
      dispath(fetchRoles({ token }));
    }
  }, [dispath, loadingRole, token]);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormInvalid(false))
      .catch(() => setIsFormInvalid(true));
  }, [form, values]);

  useEffect(() => {
    if (selectedUser && selectedUser.user_id) {
      const userId = selectedUser.user_id;
      dispath(clearSelectedUser());
      navigate(`/users/${userId}`);
    }
  }, [dispath, navigate, selectedUser]);

  if (loadingRole !== "DONE") {
    return (
      <Flex
        gap="middle"
        vertical
        className="loader-container"
        align="center"
        justify="center"
      >
        <Flex gap="middle">
          <Spin tip="Loading..." size="large">
            <div>Loading</div>
          </Spin>
        </Flex>
      </Flex>
    );
  }

  if (roleError) {
    return (
      <div className="page">
        <h3 className="error">{roleError}</h3>
      </div>
    );
  }

  const createUser = () => {
    const data = form.getFieldsValue();
    if (!data.middle_name) {
      data.middle_name = "";
    }
    dispath(CreateUser({ data, token }));
  };

  return (
    <Flex vertical gap={12} className="add-user-form">
      <Card title="Add New User" bordered={false}>
        {creatingUserError && (
          <Alert type="error" showIcon message={creatingUserError} />
        )}
        <Form
          form={form}
          name="control-hooks"
          onFinish={() => {}}
          labelCol={{ span: 4 }}
          disabled={creatingUser}
          validateTrigger="onBlur"
          initialValues={{ active: true, middle_name: "" }}
        >
          <Form.Item
            className="form-field"
            name="first_name"
            label="First Name"
            validateDebounce={1000}
            validateFirst
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="middle_name"
            label="Middle Name"
            validateDebounce={1000}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            validateDebounce={1000}
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email_address"
            label="Email Address"
            validateDebounce={1000}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              { required: true, message: "Please input your email address!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role_id"
            label="Role"
            validateDebounce={1000}
            rules={[
              { required: true, message: "Please select a role for the user" },
            ]}
          >
            <Select placeholder="Select role">
              {roles.map((role: Role) => (
                <Option
                  value={role.role_id}
                  key={`role-option-${role.role_id}`}
                >
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="active" valuePropName="checked" label="Active">
            <Checkbox></Checkbox>
          </Form.Item>
          <Flex justify="space-between">
            <Button
              color="danger"
              variant="filled"
              onClick={() => navigate(`/users/`)}
            >
              Cancel
            </Button>
            <Flex justify="flex-end" gap={12}>
              <Button
                color="danger"
                variant="filled"
                onClick={() => form.resetFields()}
              >
                Clear
              </Button>
              <Button
                disabled={isFormInvalid}
                onClick={createUser}
                loading={creatingUser}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Card>
    </Flex>
  );
};
