import { createSlice } from "@reduxjs/toolkit";

export interface IModal {
  showAccountCreateConfirmation: boolean;
}

const modalSlice = createSlice({
  name: "modals",
  initialState: {
    showAccountCreateConfirmation: false,
  } as IModal,
  reducers: {
    setShowAccountCreateConfirmation: (state, action) => {
      return { ...state, showAccountCreateConfirmation: action.payload };
    },
  },
});

export const { setShowAccountCreateConfirmation } = modalSlice.actions;
export const reducer = modalSlice.reducer;
